import "@fontsource/archivo";
import "@fontsource/amaranth";
import "@fontsource/league-gothic";

import { createApp, reactive } from 'vue'
import { createPinia } from 'pinia';
import { createRouter, createWebHashHistory } from 'vue-router'
import { OrbitConnection, BaseClass } from '@/../node_modules/orbit-component-base'
import { menu as orbit_component_zerodocs } from '@/components/zerodocs.js';
import { menu as orbit_component_shell } from '@/../node_modules/orbit-component-shell/menu.js';
import { menu as orbit_component_buttonlegal } from '@/../node_modules/orbit-component-buttonlegal/menu.js';

import App from './App.vue'
import metadata from '@/../package.json';
import '@/assets/main.css';

const router = createRouter({ history: createWebHashHistory(), routes: [] })
const pinia = createPinia()
const app = createApp(App)
app.metadata = metadata
app.metadata.components = new Map()

pinia.use(BaseClass)
app.use(OrbitConnection)

const menu = reactive([])
app.provide('$menu', menu)
orbit_component_zerodocs(app, router, menu);
orbit_component_shell(app, router, menu);
orbit_component_buttonlegal(app, router, menu);

app.use(pinia)
app.use(router)
app.mount('#app')